import logger from 'services/logger';
import { getMessageFromError } from 'utils/errorMessage';
import stopStream from 'utils/stopStream';

/**
 * Creates a singleton to manage the stream used for the Barcode Scanner.
 * This is because of a bug in Android devices where the camera is not released
 */
class BarcodeStreamManager {
  stream: MediaStream | null;
  streamError: Error | null;

  constructor() {
    this.stream = null;
    this.streamError = null;
  }

  setStream(stream: MediaStream) {
    this.cleanupStream();
    this.streamError = null;
    this.stream = stream;
  }

  cleanupStream() {
    if (!this.stream) {
      return;
    }

    stopStream(this.stream, true);
    this.stream = null;
  }

  async getVideoStream(useBasicConstraints: boolean): Promise<boolean> {
    let constraints: MediaStreamConstraints = {
      audio: false,
      video: {
        facingMode: {
          ideal: 'environment',
        },
      },
    };

    if (!useBasicConstraints) {
      constraints = {
        audio: false,
        video: {
          facingMode: {
            ideal: 'environment',
          },
          width: {
            ideal: 1920,
          },
        },
      };
    }

    try {
      logger.info('Getting user media with constraints for barcode scanner', { constraints: JSON.stringify(constraints) });

      const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
      this.setStream(mediaStream);
      return true;
    } catch (err) {
      logger.warn('Error getting user media', { error: getMessageFromError(err) });

      /**
       * Older phones sometimes don't work well with specific constraints.
       * If the stream fails to start a NotReadableError is captured and
       * I attempt to getUserMedia again, but with very basic constraints.
       */
      if ((err as Error).name === 'NotReadableError' && !useBasicConstraints) {
        logger.info('Retrying with basic constraints');
        return this.getVideoStream(true);
      }

      logger.error('Error getting user media with basic constraints', { error: getMessageFromError(err) });
      this.streamError = err as Error;
      return false;
    }
  }
}

const barcodeStreamManager = new BarcodeStreamManager();

export default barcodeStreamManager;

const stopStream = (stream: MediaStream|null, isBarcode = false) => {
  if (stream === null) {
    console.log('No stream to stop');
    return;
  }
  if (isBarcode) {
    // Get all video elements in the document
    const videoElements = document.getElementsByTagName('video');

    // Remove stream from any video elements that might be using it
    Array.from(videoElements).forEach((video) => {
      const videoStream = video.srcObject as MediaStream;
      if (videoStream?.id === stream?.id) {
        video.pause();
        video.srcObject = null;
      }
    });

    stream?.getTracks()?.forEach((track) => {
      stream?.removeTrack(track);
      track.enabled = false;
      track?.stop();
    });
    stream = null;
  } else {
    stream?.getTracks()?.forEach((track) => {
      track?.stop();
    });
  }
};

export default stopStream;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Text from 'components/Text';
import Heading from 'components/Heading';
import Button, { baseClasses } from 'components/Button';
import clsx from 'clsx';
import useVerificationStore from 'stores/verificationStore';
import { useNavigate, Link, Navigate } from 'react-router-dom';
import MainLayout from 'layouts/MainLayout';
import logger from 'services/logger';
import { loggerMessages } from 'types/logger';
import useAgeDetectionStore from 'stores/ageDetectionStore';
import mixpanel from 'services/mixpanel';

/**
 * Countries that require OCR because they do not have a barcode
 * GB - United Kingdom
 */
const ocrCountries = ['GB'];

function SelectDocType() {
  const { t } = useTranslation();
  const setDocumentKind = useVerificationStore((state) => state.setDocumentKind);
  const setDocumentType = useVerificationStore((state) => state.setDocumentType);
  const setShouldOCR = useVerificationStore((state) => state.setShouldOCR);
  const documentCountry = useVerificationStore((state) => state.documentCountry);
  const verificationFailure = useVerificationStore((state) => state.verificationFailure);
  const isRetry = useAgeDetectionStore((s) => s.isRetry);

  const navigate = useNavigate();

  useEffect(() => {
    logger.info(loggerMessages.phases.info.selectDocumentType, { aggregates: { phaseProgress: true, isRetry } });
    logger.flush();
  }, []);

  // if country is not set, then we should not be here
  if (!documentCountry) {
    return (
      <Navigate to="/select-doc-country" replace />
    );
  }

  return (
    <MainLayout includeBackButton backButtonHandler={() => navigate('/select-doc-country')}>
      <div className="flex flex-col items-center gap-4 w-full text-center mt-4">
        {verificationFailure && (
          <>
            <Heading className="text-xl">
              {t('verifyId.scanFailure.title')}
            </Heading>
            <Text>
              {t('verifyId.scanFailure.unable')}
            </Text>
          </>
        )}
        <Text className="font-bold">
          {t('verifyId.chooseId')}
        </Text>
        <Button
          id="continue-to-verify-id-link"
          className={clsx('rounded-xl px-6 h-36 flex items-center justify-center', baseClasses)}
          style={{ width: '100%', maxWidth: '25rem' }}
          onClick={() => {
            setDocumentKind('id');
            const docType = documentCountry === 'US' ? 'us-id' : 'non-us-id';
            setDocumentType(docType);
            setShouldOCR(ocrCountries.includes(documentCountry));
            logger.info('Select document type', { aggregates: { documentType: docType } });
            mixpanel.trackEvent({ event: 'Choose ID Type', documentType: docType });
            navigate('/verify-id');
          }}
        >
          <div className="flex flex-1 justify-end mr-2">
            <img src="/images/id-card.png" alt="ID Card" />
          </div>
          <div className="flex flex-1 justify-start">
            <Text>
              {documentCountry === 'DE' ? t('verifyId.germanIdCard') : t('verifyId.idCard')}
            </Text>
          </div>
        </Button>

        <Button
          id="continue-to-verify-id-info-link"
          className={clsx('rounded-xl px-6 h-36 flex items-center justify-center', baseClasses)}
          style={{ width: '100%', maxWidth: '25rem' }}
          onClick={() => {
            setDocumentType('passport');
            logger.info('Select document type', { aggregates: { documentType: 'passport' } });
            navigate('/verify-id-info');
          }}
        >
          <div className="flex flex-1 justify-end mr-2">
            <img src="/images/passport.png" alt="ID Card" />
          </div>
          <div className="flex flex-1 justify-start">
            <Text className="font-bold">
              {t('verifyId.passport')}
            </Text>
          </div>
        </Button>
        {verificationFailure && (
          <>
            <div className="w-full flex items-center max-w-sm mt-2">
              <div className="flex-1 h-px bg-slate-600" />
              <Text className="px-4">{t('or')}</Text>
              <div className="flex-1 h-px bg-slate-600" />
            </div>
            <Link to="/appeal" className="text-slate-400 font-bold underline">{t('failure.submitForReview')}</Link>
          </>
        )}
        <Text className="my-2">
          {t('verifyId.informationSecure')}
        </Text>
      </div>
    </MainLayout>
  );
}

export default SelectDocType;

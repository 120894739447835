import {
  VerifyIdErrorCodes, FaceMatchErrorCodes, Au10tixErrorCodes, AgePredictionErrorCodes,
} from 'types/logger';

/* eslint-disable max-len */
export default {
  invalidRequest: 'Demande non valide',
  pageNotFound: 'Page introuvable',
  and: 'et',
  or: 'ou',
  instruction: {
    useIdOrLicense: 'Veuillez utiliser n’importe quel permis de conduire ou pièce d’identité délivré par un État.',
  },
  loadingPage: {
    title: 'Nous mettons en place le processus de vérification sécurisé et privé',
    complete: 'TERMINÉ!',
    personal: 'Vos informations personnelles ne sont jamais collectées, stockées ou partagées.',
    init: 'Initialisation',
    pleaseWait: "Veuillez patienter pendant que le processus d'initialisation se termine.",
    safari: "Le processus d'initialisation peut prendre plus de temps avec iOS Safari.",
  },
  entry: {
    title: 'Vérification sécurisée de l’âge',
    usingAI: "À l'aide de l'intelligence artificielle avancée, nous allons d'abord essayer de vérifier votre âge avec votre apparence.",
    noBio: "Aucune donnée biométrique n'est transmise ou stockée.",
    ifUnsuccessful: "Si la tentative échoue, nous vous guiderons à travers un processus de vérification d'identité sécurisé et anonyme.",
    start: 'Démarrer la vérification',
  },
  cameraEntry: {
    title: 'Vérifions ensemble votre identité',
    firstAttempt: "Nous allons d'abord tenter de vérifier votre âge grâce à une analyse rapide d'un selfie, en utilisant la technologie de l'IA.",
    noBio: "Aucune donnée biométrique n'est transmise ou stockée",
    ready: 'Prêt à démarrer le processus ?',
    allowCamera: "Autorisez l'accès à la caméra",
  },
  prepareIdVerification: {
    title: 'Félicitations, vous semblez très jeune !',
    description: "Nous avons besoin de quelques informations supplémentaires pour vérifier votre âge, veuillez donc utiliser une pièce d'identité officielle ou un passeport.",
    restEasy: 'Vous pouvez être tranquille. Avec nous, vos informations personnelles sont toujours protégées.',
    continue: 'Continuer',
  },
  footer: {
    title: 'Vérifié par',
    terms: 'Conditions générales',
    privacy: 'Politique de confidentialité',
    biometric: 'Biométrique',
    copyright: 'Droits d’auteur',
    rights: 'Tous droits réservés.',
  },
  error: {
    mintTag: 'Erreur de frappe',
    generic: 'Un problème est survenu. Veuillez réessayer.',
    noFileSelected: 'Aucun fichier n’est sélectionné',
    dynamic: {
      [VerifyIdErrorCodes.ERR_LOAD_IMAGE]: 'Un problème est survenu. Veuillez réessayer.',
      [VerifyIdErrorCodes.ERR_DETECT_NONE]: 'Impossible de trouver de carte d’identité sur la photo fournie. Veillez à ce que la photo soit accompagnée d’une carte d’identité valide qui apparaît nettement.',
      [VerifyIdErrorCodes.ERR_DETECT_MULTIPLE]: 'Plusieurs cartes d’identité ont été détectées, veuillez ne fournir qu’une seule carte d’identité pour l’identification.',
      [VerifyIdErrorCodes.ERR_DETECT_MUGSHOT]: 'Aucune photo permettant l’identification n’a été trouvée sur la carte d’identité. Veuillez réessayer.',
      [VerifyIdErrorCodes.ERR_DETECT_DATE]: 'Nous n’avons pas pu vérifier les dates figurant sur votre photo. Veuillez télécharger une nouvelle photo et vous assurer qu’elle est bien nette',
      [VerifyIdErrorCodes.ERR_DETECT_DATE_THRESHOLD]: 'Aucune date au-dessus de l’âge minimum n’a été détectée.',
      [VerifyIdErrorCodes.ERR_DETECT_FACE]: 'Erreur de redimensionnement de la pièce d’identité avec photo et de détection du visage',
      [VerifyIdErrorCodes.ERR_DETECT_DATE_FUTURE]: 'Impossible de trouver une date d’expiration valide. Veuillez réessayer.',
      [FaceMatchErrorCodes.ERR_FACE_MATCH_ID]: 'La photo figurant sur la carte d’identité ne correspond pas à votre visage. Veuillez réessayer.',
      [FaceMatchErrorCodes.ERR_MATCHING_FACES]: 'Il y a eu une erreur concernant votre visage qui ne correspond pas. Veuillez réessayer.',
      [Au10tixErrorCodes.ERR_AU10TIX_FAIL_AGE]: 'Au10tix a détecté un âge inférieur à 18 ans.',
      [Au10tixErrorCodes.ERR_AU10TIX_FAIL_AUTHENTIC]: "Au10tix a détecté une pièce d'identité non authentique.",
      [Au10tixErrorCodes.ERR_AU10TIX_FAIL_EXPIRED]: 'Au10tix a détecté une date expirée.',
      [Au10tixErrorCodes.ERR_AU10TIX_FAIL_MAX_ATTEMPTS]: 'Le nombre maximum de tentatives de vérification via Au10tix a été atteint.',
      [AgePredictionErrorCodes.ERR_RECURSIVE_AGE_PREDICTION]: "Erreur d'estimation de l'âge.",
      [AgePredictionErrorCodes.ERR_SET_VIDEO_SOURCE]: 'Erreur de configuration de la source vidéo. Veuillez vérifier votre caméra.',
      [AgePredictionErrorCodes.ERR_NO_FACE_TIMEOUT]: "Impossible d'estimer l'âge, car aucun visage n'a été détecté.",
    },
  },
  failure: {
    title: 'Vérification échouée !',
    errorMessage: 'Il y a eu un problème lors de la vérification de votre âge. Veuillez réessayer.',
    tryAgain: 'Réessayer',
    submitForReview: 'Envoyer pour un examen manuel',
    returnToSite: 'Retourner au site',
  },
  success: {
    title: 'Félicitations, votre âge a été vérifié avec succès.',
    description: 'Vous avez vérifié votre âge avec succès.',
    continueToSite: 'Continuer vers le site',
  },
  appeal: {
    processing: 'Traitement en cours...',
    confirm: {
      photo: {
        title: 'Confirmer la photo',
        description: 'Pour continuer et demander une vérification manuelle, veuillez confirmer la photo de votre visage ci-dessous. Si vous n’êtes pas satisfait de l’image actuelle, vous pouvez choisir de la refaire pour obtenir un meilleur résultat.',
        confirmAndProceed: 'Confirmer et continuer',
        retakeNewPhoto: 'Reprendre la photo',
        takePhoto: 'Prendre la photo',
        errorAndRetake: 'Erreur lors de la de prise de la photo. Veuillez reprendre une photo.',
      },
      id: {
        title: 'Confirmez votre identité',
        description: 'Maintenant, confirmons l’image de votre carte d’identité. Assurez-vous que tous les détails sont clairs et lisibles. Si vous pensez qu’une autre photo serait plus appropriée, vous pouvez en télécharger une nouvelle maintenant.',
        confirmAndProceed: 'Confirmer et continuer',
        uploadNewIdPhoto: 'Télécharger une nouvelle photo d’identité',
        uploadPhoto: 'Télécharger une photo',
      },
    },
    success: {
      title: 'Succès',
      description: 'Merci! Nous avons reçu vos informations et nous vous contacterons bientôt.',
    },
    manualSubmission: {
      title: 'Soumission de vérification manuelle',
      description: 'Vos informations biométriques seront utilisées uniquement pour la vérification de l’âge, comme indiqué dans nos conditions générales et notre politique de confidentialité.',
      submitForManualReview: 'Envoyer pour un examen manuel',
      emailAddress: 'Adresse e-mail',
      message: 'Message (facultatif)',
      agreedToTermsAndConditionsAndPrivacyPolicy: 'En cochant cette case, vous acceptez notre',
      termsAndConditions: 'Conditions générales',
      privacyPolicy: 'Politique de confidentialité',
    },
  },
  a10tix: {
    title: "Vérification de l'identité",
    pending: 'Vérification en attente',
    verificationPending: 'Vérification en attente',
    verificationPendingDescription: 'Cela peut prendre environ une minute.',
    takingLongTimeMessage: 'Cela prend plus de temps que prévu. Pour accélérer le processus, vous pouvez télécharger une nouvelle photo de votre pièce d’identité. Assurez-vous que la photo n’est pas floue et qu’elle est bien éclairée.',
    uploadNewPhoto: 'Télécharger une nouvelle photo',
    submit: 'Envoyer',
    takeAPhoto: 'Prendre une photo',
    takeADifferentPhoto: 'Prendre une autre photo',
  },
  cameraPermission: {
    description: 'L’autorisation de la caméra est nécessaire pour utiliser cette fonctionnalité.',
    denied: 'Il semble que vous ayez refusé l’accès à la caméra pour cette page web. Vous devrez mettre à jour les paramètres de votre navigateur et actualiser la page.',
    goBack: 'Retour',
  },
  detectionMessage: {
    'Please move your face into the frame': 'Veuillez placer votre visage dans le cadre',
    'Turn your head to the left': 'Tournez la tête vers la gauche',
    'Turn your head to the right': 'Tournez la tête vers la droite',
    'Tilt your head up': 'Levez la tête',
    'Tilt your head down': 'Baissez la tête',
    'Look straight into the camera': 'Regardez droit dans la caméra',
    'Move your head slowly to fill in the circle': "Déplacez lentement votre visage pour qu'il occupe entièrement le cercle",
  },
  reviewRequest: {
    title: 'Demande d’examen',
    email: 'E-mail',
    message: 'Message',
    idImage: 'Image de carte d’identité',
    userImage: 'Image d’utilisateur',
    requestApproved: 'Demande approuvée',
    requestDenied: 'Demande refusée',
    approve: 'Approuver',
    deny: 'Refuser',
    processing: 'Traitement en cours...',
  },
  licenses: {
    title: 'Photos de licence',
    loading: 'Chargement en cours...',
    deleteAll: 'Supprimer tout',
  },
  license: {
    emblemState: 'État d’Emblem :',
    ocrStatus: 'État ROC:',
    fileId: 'N° d’identification du fichier:',
    createdAt: 'Créé à :',
    uploadReason: 'Raison du téléchargement:',
    status: 'État:',
    ocrFailureReason: 'Raison de l’échec de ROC:',
  },
  idPhotoGuidelines: {
    title: 'Veuillez noter les points suivants:',
    placeId: 'Placer la carte d’identité sur une surface plane et unicolore.',
    ensureGlareFree: 'Assurez-vous que la photo n’est pas éblouissante et qu’elle est bien éclairée.',
    allEdges: 'Tous les bords de la carte d’identité doivent se trouver dans le cadre.',
  },
  takePhoto: {
    rotateGuide: 'Tourner le guide',
    setIdFlat: 'Assurez-vous que la carte d’identité est placée sur une surface plane, qu’elle est clairement visible et que tous les bords sont dans le cadre.',
    cancel: 'Annuler',
  },
  uploadPhoto: {
    uploadPhoto: 'Télécharger la photo',
  },
  verifyId: {
    noFaceDetected: 'Aucun visage n’a été détecté sur la photo téléchargée',
    idShouldBeVisible: ' Votre carte d’identité doit être entièrement visible et lisible sur la photo.',
    submit: 'Envoyer',
    next: 'Suivant',
    retakePhoto: 'Reprendre la photo',
    rescanFace: 'Scanner de nouveau le visage',
    verificationInProgress: 'Vérification en cours...',
    pleaseWait: 'Veuillez patienter pendant la vérification de votre photo. Cela ne devrait prendre que quelques secondes.',
    chooseId: "Choisissez le type de pièce d'identité",
    idCard: "Permis de conduire ou carte d'identité",
    germanIdCard: "Carte d'identité allemande",
    passport: 'Passeport',
    selectCountry: "Sélectionnez le pays d'émission de votre document :",
    continue: 'Continuer',
    frontOfId: "Prenez une photo du recto de votre pièce d'identité",
    faceNoMatch: 'La photo figurant sur la carte d’identité ne correspond pas à votre visage.',
    informationSecure: 'Vos informations restent sécurisées et confidentielles.',
    scanFailure: {
      title: 'La vérification a échoué',
      unable: "Nous sommes désolés, mais nous n'avons pas pu vérifier votre âge avec le document fourni. Essayons à nouveau.",
    },
  },
  agePrediction: {
    settingUpCamera: 'Configuration de la caméra',
  },
  human: {
    errorInitializing: 'Erreur d’initialisation',
    errorLoadingModel: 'Erreur lors du chargement du modèle',
    pleaseCloseWindowAndTryAgain: 'Veuillez fermer cette fenêtre et réessayer',
    incompatibleWebview: "Le navigateur actuel n'est pas pris en charge. Si vous êtes dans une vue Web, veuillez ouvrir le lien dans un navigateur",
  },
  verifyIdInfo: {
    passport: {
      title: "Numérisez la page d'identification de votre passeport",
      // @todo: atg translation needed
      instruction: [
        '• Placez le document à plat et assurez-vous qu\'il est bien éclairé',
        '• Ajustez le document entier dans le cadre',
        '• Alignez le code en bas avec le guide',
      ].join('<br/>'),
    },
    'non-us-id': {
      title: "Numérisez le verso de votre pièce d'identité",
      // @todo: atg translation needed
      instruction: [
        '• Placez le document à plat et assurez-vous qu\'il est bien éclairé',
        '• Ajustez le document entier dans le cadre',
        '• Alignez le code en bas avec le guide',
      ].join('<br/>'),
      // instruction: "Instructions relatives aux pièces d'identités émises en dehors des États-Unis",
    },
    'us-id': {
      title: "Numérisez le verso de votre pièce d'identité",
      instruction: "Instructions relatives aux pièces d'identités émises aux États-Unis",
    },
    scanBarcode: 'Cherchez et numérisez ce code-barres',
    cantFindBarcode: "Impossible de trouver un code-barres sur votre pièce d'identité ? Appuyez sur « Passer » pour continuer.",
    skipBarcode: 'Passer',
  },
  faceAngleMessage: {
    moveFace: 'Veuillez placer votre <br /> visage dans le cadre',
    lookStraight: 'Regardez droit dans <br /> la caméra',
    turnHead: 'Tournez la tête',
    keepTurningClockwise: 'Tournez la tête <br /> dans le sens <br /> horaire',
  },
  signup: {
    addBillingInfo: 'Ajouter des informations de facturation',
    billingDescription: 'Pour compléter votre intégration avec SafePassage, veuillez fournir vos informations de facturation. Cette information sera utilisée pour facturer les services de SafePassage liés à Emblem.',
    noCreditCardRequired: 'Aucune carte de crédit n\'est requise pour le moment. Vous serez facturé en fonction de l\'utilisation.',
    accessibleThroughEmblem: 'Tous les détails d\'utilisation seront disponibles via Emblem.',
    companyNameLabel: 'Nom de l\'entreprise (ou nom complet si non représentant une organisation)',
    companyNamePlaceholder: 'ex: Acme Corp / John Doe',
    billingEmailLabel: 'E-mail du contact de facturation',
    billingEmailPlaceholder: 'ex: billing@acmecorp.com',
    billingEmailHelper: 'Les factures et les informations de facturation seront envoyées ici.',
    billingAddressLabel: 'Adresse de facturation',
    billingAddressPlaceholder: 'Adresse, Ville, État/Région, Code postal, Pays',
    billingAddressHelper: 'Veuillez saisir une adresse complète pour la facturation.',
    phoneNumberLabel: 'Numéro de téléphone (optionnel)',
    phoneNumberPlaceholder: 'ex: +1 234 567 890',
    phoneNumberHelper: 'Pour les suivis liés à la facturation, si nécessaire.',
    termsAgreement: "J'accepte d'être facturé pour les services SafePassage liés à Emblem à 0,08 $ par vérification d'âge réussie. Aucune carte de crédit n'est requise pour le moment, et j'accepte les conditions du contrat de paiement.",
    completeSignup: 'Terminer l\'inscription',
    needHelp: 'Besoin d\'aide ?',
    contactSupport: 'Contactez-nous à support@safepassage.com',
  },
};

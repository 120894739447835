import { useState, useEffect } from 'react';
import barcodeStreamManager from 'services/BarcodeStreamManager';

interface StreamState {
  streamError: Error | null;
  streamReady: boolean;
}

const useVideoStream = () => {
  const [streamState, setStreamState] = useState<StreamState>({ streamError: null, streamReady: false });
  const { streamReady, streamError } = streamState;

  useEffect(() => {
    if (!streamReady) {
      return undefined;
    }

    /**
     * Stop any tracks associated with requested MediaStream
     */
    return () => {
      barcodeStreamManager.cleanupStream();
    };
  }, [streamReady]);

  useEffect(() => {
    const getVideoStream = async () => {
      const ready = await barcodeStreamManager.getVideoStream(false);
      setStreamState({
        streamError: barcodeStreamManager.streamError,
        streamReady: ready,
      });
    };
    getVideoStream();
  }, []);

  return {
    streamReady, streamError,
  };
};

export default useVideoStream;
